import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #010 Edgar Huitema - The Beerologist"}
    blogUrl={"https://joincrafted.com/010-edgar-huitema-the-beerologist"}
    description={
      "Science as creative expression; Beer as art. Microbiologist scientist-brewer, Edgar Huitema, discusses his path to brewing and how it intersects with science."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/huitema/yeast-trapping.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=Microbiologist%20scientist-brewer,%20Science%20as%20creative%20expression;%20Beer%20as%20art.%20Edgar%20Huitema,%20discusses%20his%20path%20to%20brewing%20and%20how%20it%20intersects%20with%20science.%0A%0Ahttps%3A//joincrafted.com/010-edgar-huitema-the-beerologist%0A%0A"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/010-edgar-huitema-the-beerologist"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>December 14, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          <tr bgcolor="#ffffff" style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;010 🍻 - Edgar Huitema, The Beerologist</h1>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', marginBottom: '24px'}}>
                                                <p>We've made it to double-digits! <strong>10 Crafted editions</strong> 🎉 Thank you guys for making it possible.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', marginBottom: '16px'}}>
                                                <p>The 10th edition had to be something special. </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px', marginBottom: '32px', paddingBottom: '8px'}}>
                                                That's why I'm excited to introduce <strong>Edgar Huitema</strong> who writes at <a href="https://beercollection.substack.com/" style={{color: '#6821AB', fontWeight: 700, textDecoration: 'underline'}} target="_blank" rel="noreferrer"><strong>The Beerologist</strong></a>, newsletter and consulting service that helps brewers understand the science behind their craft in order to brew their best beer.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', marginBottom: '24px'}}>
                                                <p>He's written on how to <a href="https://beercollection.substack.com/p/my-yeast-trapping-experiment" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">isolate and use wild yeast</a>, what makes <a href="https://beercollection.substack.com/p/malty-not-salty-d66" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">good malt and how to recognize it</a>, and many other fascinating topics where micro-level science intersects with brewing.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', marginBottom: '24px'}}>
                                                <p>What follows is a discussion around what drives this microbiologist-brewer, and how thinks about brewing. Including:
                                                </p>
                                                <ul>
                                                  <li>Science as creative expression, beer as art</li>
                                                  <li>Humility's role in science and homebrewing</li>
                                                  <li>The value in analyzing your product, and hope for a cost-effective way to do it</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                                <EmailFormInPage />
                                              </div></td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>How'd you get into brewing? What makes it special to you?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I started brewing when my girlfriend (now my wife) gave me a brew kit. It was fairly basic. I got two buckets, some hoses, an aluminium pan, bottles and caps. Very rudimentary. We both loved beer, and she thought I'd enjoy making my own. At the time, I was getting tired of drinking the major lager brands and more into enjoying craft beers. The US was perfect for that as microbreweries were popping up everywhere.</p>
                                                <p style={{paddingBottom: '16px'}}>Along with that, brew shops and clubs came into view. Joining a brew club became one of the things I immediately enjoyed. I just loved having other "experts" taste my brews. Most of the beer styles I now know of, I got to taste for the first time at these meetings. Very educational on many different levels.</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="https://beercollection.substack.com/p/catch-wild-yeast"><img src="/assets/images/huitema/yeast-trapping.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Edgar's setup for a yeast trapping experiment
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5'}}>
                                                Image source: <a style={{textDecoration: 'none', color: '#28324E', wordWrap: 'break-word'}} target="_blank" rel="noreferrer" href="https://beercollection.substack.com/p/catch-wild-yeast">https://beercollection.substack.com/p/catch-wild-yeast</a></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What does brewing and craft beer mean to you? Why do you do it?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Brewing attracts me for various reasons. Firstly, I like the creative process. I am a very creative person. The problem, however, is that I do not have the skills artists use to express themselves. I am not a good singer, I can hold a guitar but.., and my drawing skills are pretty basic. I struggled with language, as well. My family is quite good at crafts (building, DYI etc.) but again, I wasn't.</p>
                                                <p style={{paddingBottom: '16px'}}>I grew up thinking that I am confined to a life devoid of creative expression. I genuinely asked myself what my talents were. I now appreciate it was quite distressing at times. Science changed that for me. I discovered that in research, creativity is critical, and I thrived there. I just found a means to express my creativity and get that buzz. I discovered my talents in the process. These were exciting times.</p>
                                                <p style={{paddingBottom: '16px'}}>As with scientific research, brewing opens up a new realm of creative expression. Not only can I channel my creativity into the beers I make, but also, I have a small audience that appreciates the art that flows from my bottles! It is the interaction with my "audience" and the feedback that I receive I enjoy. And the beers, of course! I now understand why I loved the brew club so much.</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/huitema/homebrew-edgar.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Edgar &amp; a homebrew - surely with yeast captured from the wild :)
                                              </div>
                                            </td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Is there a benefit to being a scientist and a brewer?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>There has been a benefit for me though I should make it clear that it does not matter if you are not a scientist. Many of the people I have brewed with over the years are not scientists and made stunning beers. I've also tasted beers made by scientists that were not that good, I suppose. My scientific training has helped me become curious to the point where I seek information from sources and confident in my ability to make sense of it and use exciting concepts for my brewing.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Can you give some examples?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Isolation of wild yeast is a good example. I am well informed and aware of research in (molecular) microbiology. The techniques and approaches that I have used over the years allow me to go out and isolate wild yeasts for brewing. I wrote a few articles about it for my newsletter, and now realise that many brewers are interested but don't know how to go about things. I am trying to write more about this now.</p>
                                                <p style={{paddingBottom: '16px'}}>For me, another good example is the humility with which we have to tackle a particular problem. As a scientist, you are always peering into the dark, making sense of the world in ways that nobody ever has before. I am pretty sure that most experiments I do, never have been done before. This means that nobody knows what the experiment will reveal. We are trained to deal with whatever the answer is because most of the time, it proves us wrong. Brewing is the same for me. Per definition, every home-brewed beer on this planet will be unique in some way, and the same is true for every brewer. I, therefore, approach each interaction with the community as a valuable discovery. Nobody knows best. We all know different.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>As a scientist, if you were starting a brewery, how would you set it up? What would you be sure to pay attention to?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Again, being a scientist does not give me a particular advantage. I probably look after my yeast very well, but struggle with (most) other (mechanical) processes that require different skill sets. The one aspect that I have become quite good at as a scientist is troubleshooting. Meticulous note-taking and record-keeping are vital for tackling inconsistencies. What do I usually do when I brew beer X, and how is it that now, my beer X is going off? Any changes to my ingredients? Or process?</p>
                                                <p style={{paddingBottom: '16px'}}>The other thing I have learned is how critical it is to work in teams. Interdisciplinary research is the new normal in most fields, and because of this, I appreciate the power of diverse skills sets when brought together into effective teams. If I were to start a brewery, I would team up with someone who (i) shares my vision, (ii) my values and (iii) offers complementary and relevant skills set. Alternatively, I would build an (informal) network that can provide the expertise I need. Having started a small business myself recently, I make use of the latter strategy, though not deliberately. My network is organic.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What are some common pitfalls that you've run into which can be avoided by breweries? Do you have any examples?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I am still learning about breweries and their problems. I think that when you look at the industry that supports (small) breweries, there is a focus on quality control, safety and microbiological contamination. Analytical outfits have become important, and I think most brewers will have had their water, wort or beer analysed in some way. If they haven't, I would recommend that.</p>
                                                <p style={{paddingBottom: '16px'}}>In my experience, however, brewers struggle to set up their sampling and analyses such that they received the right information or answers. Especially when you try to tackle a problem, Where do you start? How do I sample? What sort of analyses is appropriate? What do my results mean? What do I do now? These are all questions or issues that scientific support can help address.</p>
                                                <p style={{paddingBottom: '16px'}}>I am currently working with one brewery that is setting up lab capability. They have a problem with beer quality that typical microbiology analyses do not seem to address. Having a microscope and culturing capability is worthwhile simply because it is cost-effective, and because commercial analytical companies do not always offer bespoke follow-up analyses. I bring my expertise to the table so that brewers can investigate their problems more in-depth and get attuned to the detail of their processes. A relatively small investment in a kit and some know-how can go a long way in tackling most problems.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What does your solution look like?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>With <a style={{color: '#6821AB', textDecoration: 'underline'}} href="https://beercollection.substack.com/" target="_blank" rel="noreferrer">my newsletter</a> and its subscription model, I hope to fill this gap between advanced analytics and science on the one hand, and brewers on the other. A free signup option gives subscribers access to my articles that talk about the science of brewing. Take from it as much as you like!</p>
                                                <p style={{paddingBottom: '16px'}}>As a paying member, you have direct access to my bespoke services such as consultation, help with experiment design, data analysis, and literature searches. I am trying to answer the questions that matter to brewers.</p>
                                                <p style={{paddingBottom: '16px'}}>In essence, brewers have access to 20 years of experience in cutting-edge molecular biology &amp; microbiology research for only a modest fee. I think that scientific expertise, combined with already available advanced analytical services and a small lab setup, will set brewers up for success. The Beerologist is here to help brewers, big and small, achieve that!</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="https://beercollection.substack.com/about"><img src="/assets/images/huitema/edgar.jpg" width={483} style={{width: '78%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Edgar Huitema, The Beerologist
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', paddingBottom: '12px'}}>
                                                <a style={{textDecoration: 'none', color: '#28324E', wordWrap: 'break-word'}} target="_blank" rel="noreferrer" href="https://beercollection.substack.com/about">https://beercollection.substack.com/about</a></div>
                                                <EmailFormInPage />
                                                </td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}> <tbody> <tr> <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>As brewers, we <em>create</em>. It is creative expression, "art that flows from bottles". It is art that must be approached with humility. It is art that is fleeting and unique. Every home-brewed beer is one-of-kind and will disappear forever when it acheives its purpose.</p>
                                                <p style={{paddingBottom: '8px'}}>I love the way Edgar talks beer. I appreciate the ideas that he stirs up in my mind.</p>
                                                <p>If you feel the same, you would benefit from checking out more of his work. He's got some great, pratical science that you can apply to your own brewing processes.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0', marginBottom: '32px'}}>
                                                Thank you <strong>Edgar</strong> for sharing your time and words with us. You love the Craft and it is beautiful to see. The world could benefit from some more microbiologist-brewers like you.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}> <tbody> <tr> <td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I also want to thank you all for reading this. Again, you make it worth it. I enjoy interacting with this community and seeing it grow.</p>
                                                <p style={{paddingBottom: '16px'}}>I'd love to hear from you - just reply to this email. Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px'}}>
                                                <div style={{padding: '8px'}}>
                                                  One of the best places to get a variety of hops is <strong>Yakima Valley Hops</strong> - and they're currently sponsoring this newsletter. <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit" style={{textDecoration: 'underline', color: '#6821AB'}} target="_blank" rel="noreferrer">Check out their new hop aromas kit</a>, made for brewers to hone their hop noses and refine the language of their beer.
                                                </div>
                                              </div></td>
                                          </tr>
                                        </tbody>
                                      </table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '-8px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit"><img src="/assets/images/yakima_valley_hops_kit_1000x500.jpeg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} alt="Yakima Valley Hops Kit" /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
